.ant-form-item { margin-bottom: 7px !important; }
#myForm .ant-input { padding: 7px 11px !important; }
.my-form .ant-input { padding: 7px 11px !important; }
#myForm .ant-input-number-input { padding: 7px 11px !important; height: unset !important; }
.my-form .ant-input-number-input { padding: 7px 11px !important; height: unset !important; }
.hidden { display: none !important; }
.striking-logo img {
    max-height: 35px !important;
    width: unset !important;
    max-width: unset !important;
}
.ant-popover { z-index: unset !important; }
.table-responsive .ant-table-content .ant-table-cell { white-space: unset !important; }
.ant-card-body { padding: 1px !important;}
.ant-pagination { margin: 0 !important; }
.ant-table-pagination { padding-top: 15px !important; }
/* .anticon { vertical-align: unset !important; } */
.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td { padding: 8px 16px !important; }
.ant-page-header {
    padding: 20px !important;
    border-radius: 10px !important;
    margin-top: 30px !important;
}
.login-form {
    margin-top: 10% !important;
    padding: 20px !important;
    background-color: #fff !important;
    border-radius: 10px !important;
}
.pl-5 {
    padding-left: 5px;
}
.pr-5 {
    padding-right: 5px;
}
.pt-5 {
    padding-top: 5px;
}
.pb-5 {
    padding-bottom: 5px;
}
.ant-btn-sm {
    height: 24px;
    padding: 0 7px;
    font-size: 14px;
    border-radius: 4px;
}
.ant-descriptions {
    margin-bottom: 15px;
}
.minimum-mt {
    margin-top: 5px;
}
.border-radius-3 {
    border-radius: 3px;
}
.img-size {
    width: 50px;
    height: 50px;
    border: 1px solid wheat;
}
.img-size-2 {
    width: 80px;
    height: 80px;
    border: 1px solid wheat;
}
.logo-size-4-print {
    width: 50px;
    height: 50px;
    margin-bottom: 20px;
    border: 1px solid wheat;
}
.img-size-3 {
    width: 120px;
    height: 120px;
    border: 1px solid wheat;
}
.no-padding {
    padding: 0 !important;
}
.no-margin {
    margin: 0 !important;
}
.pointer {
    cursor: pointer;
}
.button-photo {
    width: fit-content;
    height: 22px;
    padding-right: 10px;
    padding-left: 10px;
    background-image: linear-gradient(rgb(149, 148, 155), rgb(54, 97, 107));
    border: none;
    border-radius: 5px;
    color: rgb(255, 255, 255);
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
    box-shadow: 0px 0px 0px rgb(139, 113, 255);
    transition-duration: .3s;
}
/* Guardian Button */
.button-guardian {
    width: 110px;
    padding: 0;
    border: none;
    transform: rotate(0deg);
    transform-origin: center;
    font-family: "Gochi Hand", cursive;
    text-decoration: none;
    font-size: 14px;
    padding-bottom: 3px;
    border-radius: 5px;
    box-shadow: 0 2px 0 #494a4b;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    background-color: #5CDB95;
}

.button-guardian span {
    background: #f1f5f8;
    display: block;
    padding: 1px !important;
    border-radius: 5px;
    border: 2px solid #494a4b;
}

.button-guardian:active {
    transform: translateY(5px);
    padding-bottom: 0px;
    outline: 0;
}
/* Guardian Button */
.minimum-mr {
    margin-right: 5px;
}
.border-danger {
    border-color: #ff4d4f;
}
.border-success {
    border-color: #20c997;
}
.border-primary {
    border-color: #e67e22;
}
.border-secondary {
    border-color: #ff69a5;
}
.border-info {
    border-color: #2c99ff;
}
.list-input {
    width: 100% !important;
}
.table-fixed-width {
    width: 100px;
}
.text-right {
    text-align: right;
}
.text-bold {
    font-weight: bold;
}
.ant-timeline-item-last {
    padding-bottom: 0px !important;
}
.ant-timeline-item {
    padding-bottom: 0px !important;
}

.mr-10 {
    margin-right: 10px !important;
}
.mr-6 {
    margin-right: 6px !important;
}
.page-header {
    margin-top: 0px !important;
}

/*reportCard*/

.reportCard {
    /*border-left: 20px solid #f1b15c;*/
    /*border-right: 20px solid #f1b15c;*/
    background-color: #fff;
    width: 880px;
    /*height: 1136px;*/
    height: 1236px;
    padding-left: 10px;
    padding-top: 17px;
    margin-left: 10px;
    margin-top: 0;
    margin-bottom: 5px;
    /*box-shadow: 2px 2px 10px #ccc;*/
    transition: 0.5s ease;
}
.card-header {
    /*padding: 10px;*/
    display: flex;
    align-items: center;
}
.card-header-text {
    display: block;
    font-family: sans-serif !important;
}
.card-header h1 {
    margin-top: 20px;
    line-height: 45px;
    /*font-family: "Allura", cursive;*/
    font-size: 3.8rem;
    font-weight: bold;
    color: #173617;
    margin-bottom: 0 !important;
}
.card-header h4 {
    /*font-family: "Allura", cursive;*/
    font-size: 2.5rem;
    font-weight: bold;
    line-height: 60px;
    color: #173617;
    margin: 0;
    letter-spacing: 0.05em;
}

.card-header h5 {
    /*font-family: "Allura", cursive;*/
    font-size: 0.9rem;
    font-weight: bold;
    color: #722004;
    margin: 0;
    letter-spacing: 0.12em;
}
.logo-image{
    height: 132px;
    width: 132px;
    margin-left: 5px;
    /*border-radius: 50%;*/
    margin-right: 10px
}
.card-header-line {
    font-family: "Allura", cursive;
    color: #ffffff;
    font-weight: bold;
    letter-spacing: 0.2em;
    margin-top: 5px;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
    text-align: center;
    background-color: #f1b15c;
    height: 20px;
}
.profile-image{
    margin-left: 30px;
    height: 150px;
    width: 150px;
    padding: 3px;
    border: 2px solid #ffde7b;
    /*border-radius: 50%;*/
}
.student {
    text-align: right;
    padding-right: 10px;
}
.student-line {
    font-family: "Allura", cursive;
    color: #706b70;
    margin-bottom: 2px;
    font-weight: bold;
    letter-spacing: 0.1em;
    margin-top: 5px;
    padding-left: 7px;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
    text-align: left;
    font-size: 12px;
    background-color: #fdf1e4;
    min-width: 20px !important;
    height: 20px;
}
.student-info{
    margin-top: 20px;
}
.card-body {
    padding: 10px;
}

/*table*/

.quarter-table {
    margin-top: 40px;
    width: 600px;
    font-size: 15px;
}
.quarter-td, .quarter-th {
    padding: 8px;
    border: 1px solid #c2bdbd;
    border-collapse: collapse;
    text-align: center;
}
.quarter-td {
    font-size: 15px;
}
.sub-left-td {
    text-align: left !important;
}
.quarter-th {
    letter-spacing: 0.8px !important;
    background-color: #e3805d;
    color: #fff;
}
.quarter-tr:nth-child(odd) {
    line-height: 20px;
    background-color: #f3fafa;
    border: 1px solid lightgray;
}

.quarter-grade-table {
    margin-top: 40px;
    margin-left: 25px;
    width: 200px;
    height: 100px;
}
.quarter-grade-th {
    letter-spacing: 0.8px !important;
    background-color: #e3805d;
    color: #fff;
}
.quarter-grade-table, .quarter-grade-td, .quarter-grade-th {
    padding: 10px;
    border: 1px solid lightgray;
    border-collapse: collapse;
    text-align: center;
}

.quarter-attendance-table {
    margin-top: 40px;
    margin-left: 25px;
    width: 200px;
    height: 100px;
}
.quarter-attendance-th {
    letter-spacing: 0.8px !important;
    background-color: #e3805d;
    color: #fff;
}
.quarter-attendance-table, .quarter-attendance-td, .quarter-attendance-th {
    padding: 10px;
    border: 1px solid lightgray;
    border-collapse: collapse;
    text-align: center;
}

.quarter-total-table {
    margin-top: 20px;
    width: 550px;
}

.quarter-total-th {
    background-color: #a98898;
    color: #fff;
    border: 1px solid lightgray;
}
.quarter-total-th:nth-child(even){
    background-color: #fff;
    color: #5a5f7d;
    text-align: left;
    padding-left: 5px;
}
.quarter-total-th:nth-child(odd){
    background-color: #f3fafa;
    color: #5a5f7d;
    text-align: center;
}

.quarter-feedback-table {
    margin-top: 30px;
    width: 250px;
}
.quarter-feedback-table, .quarter-feedback-td, .quarter-feedback-th {
    padding: 5px;
    border: 1px solid lightgray;
    border-collapse: collapse;
    text-align: center;
}
.quarter-feedback-td {
    font-size: 12px;
}
.quarter-feedback-th {
    background-color: #e3805d;
    color: #fff;
}
.quarter-feedback-td:nth-child(1) {
    background-color: #fdfdfd;
    padding-left: 15px !important;
    border: 1px solid lightgray;
    text-align: right;
}
.quarter-feedback-td:nth-child(2) {
   border-collapse: collapse;
}

.quarter-co-table {
    margin-top: 30px;
    width: 100px;
}
.quarter-co-th {
    background-color: #e3805d;
    color: #fff;
}
.quarter-co-table, .quarter-co-td, .quarter-co-th {
    border: 1px solid lightgray;
    border-collapse: collapse;
    text-align: center;
}

.quarter-achievement-table {
    margin-top: 20px;
    width: 100px;
}
.quarter-achievement-th {
    background-color: #e3805d;
    color: #fff;
}
.quarter-achievement-table, .quarter-achievement-td, .quarter-achievement-th {
    border: 1px solid lightgray;
    border-collapse: collapse;
    text-align: center;
}

.feedback-header {
    font-family: 'Shantell Sans', cursive;
    color: #ffffff;
    letter-spacing: 0.8px !important;
    font-weight: bold;
    font-size: 13px;
    margin-top: 5px;
    margin-right: 2px;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
    text-align: right;
    padding-right: 5px;
    background-color: #e89d89;
    height: 25px;
}
.feedback-body {
    font-family: 'Cinzel', serif;
    color: #1a045e;
    letter-spacing: 0 !important;
    font-size: 13px;
    font-weight: bold;
    margin-top: 5px;
    text-align: center;
    box-shadow: rgba(149, 157, 165, 0.2) 1px 8px 24px;
    background-color: #ffffff;
    height: 25px;
}

.feedback-main-header {
    margin-left: 12px;
    letter-spacing: 0.1em;
    font-family: 'Tilt Prism', cursive;
    color: #1e0e49;
    font-size: 15px;
    font-weight: bold;
    margin-top: 20px;
    border-radius: 3px;
    text-align: left;
    height: 25px;
}
.co-main-header {
    letter-spacing: 0.2em;
    font-family: 'Tilt Prism', cursive;
    color: #1e0e49;
    font-size: 15px;
    font-weight: bold;
    margin-top: 20px;
    margin-right: 7px;
    margin-left: 7px;
    border-radius: 3px;
    text-align: left;
    height: 25px;
}
.co-header {
    list-style-type: "*";
    margin: 4px;
    letter-spacing: normal;
    font-family: 'Shantell Sans', cursive;
    color: #fcfbfb;
    font-weight: bold;
    font-size: 14px;
    border-radius: 3px;
    text-align: left;
    padding: 2px;
    background-color: #5eb3c0;
}
.co-div {
    background-color: #f7f4fa;
    height: auto;
    padding-bottom: 3px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.ach-main-header {
    letter-spacing: 0.2em;
    font-family: 'Tilt Prism', cursive;
    color: #1e0e49;
    font-size: 17px;
    font-weight: bold;
    margin-top: 20px;
    margin-left: 25px;
    border-radius: 3px;
    text-align: left;
    height: 25px;
}
.ach-header {
    list-style-type: "*";
    margin: 4px;
    letter-spacing: normal;
    font-family: 'Shantell Sans', cursive;
    color: #fcfbfb;
    font-weight: bold;
    font-size: 14px;
    border-radius: 3px;
    text-align: left;
    padding: 2px;
    background-color: #b689bd;
}

.ach-div {
    background-color: #f7f4fa;
    height: 145px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.tabulationBox {
    width: 35px;
    height: 30px;

    text-align: center;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
/* Preschool */
.preschool-feedback-header {
    font-family: 'Shantell Sans', cursive;
    color: #ffffff;
    letter-spacing: 0.8px !important;
    font-weight: bold;
    font-size: 13px;
    margin-top: 5px;
    margin-right: 2px;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
    text-align: right;
    padding-right: 5px;
    background-color: #e89d89;
    margin-bottom: 7px;

}
.preschool-feedback-body {
    font-family: 'Cinzel', serif;
    color: #1a045e;
    letter-spacing: 0 !important;
    font-size: 13px;
    font-weight: bold;
    margin-top: 5px;
    text-align: center;
    box-shadow: rgba(149, 157, 165, 0.2) 1px 8px 24px;
    background-color: #ffffff;
    width: 100%;
    height: 100%;
}

.preschool-feedback-main-header {
    margin-left: 5px;
    letter-spacing: 0.1em;
    font-family: 'Tilt Prism', cursive;
    color: #1e0e49;
    font-size: 17px;
    font-weight: bold;
    margin-top: 20px;
    border-radius: 3px;
    text-align: left;
    height: 25px;
    width: 100%;
    height: 100%;
}
/* Preschool */

/* Preschool Remarks */
.pre-card {
    height: auto;
    width: 100%;
    padding: 0;
    box-shadow: rgba(149, 157, 165, 0.2) 1px 8px 24px;
    background: #ffffff;
    border-radius: 10px;
    display: flex;
    margin-bottom: 5px;
    flex-direction: column;
    justify-content: space-between;
}

.pre-title, .pre-more {
    margin-bottom: 1px;
    margin-top: 5px;
    padding-bottom: 1px;
}

.pre-user {
    padding-bottom: 2px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

}

.pre-user__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
}
.pre-text {
    padding-left: 5px !important;
    line-height: 14.5px;
    padding-top: 3px !important;
    padding-bottom: 3px !important;
}

.pre-user__container {
    display: flex;
    flex-direction: column;
}

.pre-title {
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.1em;
    color: #b2115d;
}

.pre-name {
    /*font-family: 'Shantell Sans', cursive;*/
    font-family: 'sans-serif', cursive;
    color: #2a1d52;
    margin: auto;
    padding-right: 8px !important;
    letter-spacing: 0.8px !important;
    font-size: 12px;
    font-weight: 500;
    width: 50px;
}

.pre-username {
    font-size: .9em;
    color: #64696e;
}

.pre-image {
    width: 60px;
    height: 60px;
    background: rgb(22,19,70);
    background: linear-gradient(295deg, rgba(22,19,70,1) 41%, rgba(89,177,237,1) 100%);
    border-radius: 50%;
    margin-right: 15px;
}

.pre-follow {
    font-family: 'Shantell Sans', cursive;
    border: none;
    border-radius: 5px;
    background-color: white;
    color: #191425;
    letter-spacing: 0.7px !important;
    padding: 1px 3px;
    margin-right: 8px;
    font-size: 11px;
    font-weight: 600;

}

.pre-more {
    display: block;
    text-decoration: none;
    color: rgb(29, 155, 240);
    font-weight: 800;
}

.pre-user:hover {
    background-color: #b3b6b6;
}

.pre-more:hover {
    background-color: #b3b6b6;
    border-radius: 0px 0px 15px 15px;
}

.pre-follow:hover {
    /*background-color: #2c3136;*/
}
/* Preschool Remarks */
.csv-link {
    text-decoration: none; /* Remove underline */
    color: #fff; /* Text color */
}

.csv-button {
    background-color: #5cb85c; /* Button background color */
    border-color: #4cae4c; /* Button border color */
    /* Add more styling properties as needed */
}
.pdfBox {
    min-width: 40px;
    min-height: 30px; /* Set the height as needed */
    background-color: #f8f7f7;
    border: 1px solid #b9b5b5;
    /*box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;*/
}

/* Add this CSS to your styles or create a new stylesheet */

.even-row-pdf,
.odd-row-pdf {
    line-height: 1.2; /* Adjust the line-height to reduce row height in PDF */
}

.even-row td {
    font-size: 11px;
    border: 0.5px solid #27869b;
    /*background-color: #f5f5f5; !* Set the background color for even rows *!*/
}

.odd-row td {
    font-size: 11px;
    border: 0.5px solid #27869b;
    /*background-color: #ffffff; !* Set the background color for odd rows *!*/
}
.page-control {
    margin-top: 5px !important;
}
/*.page-break {*/
/*    page-break-inside: avoid;*/
/*}*/

/*.table-responsive {*/
/*    border: 1px solid black; !* Set the border color for the entire table *!*/
/*}*/

/*.border-box {*/
/*    margin: 5px;*/
/*    border: 1px solid black; !* Set the border color for the border boxes *!*/
/*    padding: 8px; !* Optional: Add padding for better spacing *!*/
/*}*/

.margin-reduce {
    margin-top: 5px !important;
}
.list-square {
    font-size: 13px;
    padding-left: 20px;
    list-style-type: square;
}
.list-na {
    padding-left: 5px;
    font-size: 13px;
}
.red-text {
    color: #b70557;
}