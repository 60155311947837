@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;700&display=swap");

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}


.card {
    padding: 15px;
    width: 350px;
    background: #fff;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.7);
    user-select: none;
}

.cover-photo {
    position: relative;
    background: url(https://sing-hurdco.s3.ap-southeast-1.amazonaws.com/academic/selected-photos/dark.jpg);
    background-size: cover;
    height: 686px;
    border-radius: 5px 5px 0 0;
}

@media (max-width: 767px) { /* Adjust the breakpoint as needed */
    .cover-photo {
        height: 180px !important;
    }
}

.profile-name-custom {
    font-size: 18px !important;
    color: coral !important;
    font-weight: bold;
}

.profile {
    position: absolute;
    width: 120px;
    height: 125px;
    bottom: -50px;
    left: 15px;
    border-radius: 50%;
    border: 1px solid #222;
    background: #f8f9fa;
    padding-top: 3px;
}

.profile-name {
    font-size: 15px;
    /*margin-top: 30px !important;*/
    /*margin: 0 0 0 130px;*/
    color: #2a1d52;
}

.profile-section {
    font-size: 18px !important;
    color: coral !important;
    font-weight: bold;
}

.about {
    margin-top: 30px;
    line-height: 1.6;
}

.btn-user-profile {
    margin: 5px 5px;
    background: #7c043b;
    padding: 3px 10px;
    border-radius: 3px;
    border: 1px solid #7ce3ff;
    font-weight: bold;
    font-size: 12px;
    font-family: Montserrat;
    cursor: default;
    color: #222;
    transition: 0.2s;
}

.btn-user-section {
    margin: 10px 10px;
    /*background: #7c043b;*/
    color: coral;
    padding: 3px 10px;
    border-radius: 3px;
    border: 1px solid #7ce3ff;
    font-weight: bold;
    font-family: Montserrat;
    cursor: default;
    transition: 0.2s;
}

.btn-user-profile:last-of-type {
    background: transparent;
    border-color: #bb1863;
    color: #7c043b;
}

.btn-user-profile:hover {
    background: #bdcada;
    color: #222;
}

.icons {
    width: 180px;
    margin: 0 auto 10px;
    display: flex;
    justify-content: space-between;
    gap: 15px;
}

.icons i {
    cursor: pointer;
    padding: 5px;
    font-size: 18px;
    transition: 0.2s;
}

.icons i:hover {
    color: #7ce3ff;
}


/* Add this CSS to your profile.css file or any CSS file where you manage styles */

/* Adjustments for smaller screens */
@media (max-width: 768px) {
    .header-buttons {
        display: block;
        position: fixed;
        text-align: center; /* Center buttons horizontally on smaller screens */
        margin-bottom: 16px; /* Add space between buttons and content */
    }
    .routine-btn-user-profile,
    .academic-btn-user-profile {
        margin-right: 8px; /* Add space between buttons */
        margin-bottom: 8px; /* Add space between buttons vertically */
    }
}

.custom-ant-page-header {
    padding: 0 !important;
    margin: 0 !important;
}

@media (max-width: 768px) {
    .ant-descriptions-item-content {
        padding: 8px 16px; /* Adjust the padding values as needed */
    }
}


.section-title {
    color: #1890ff;
    font-size: 24px;
    margin-top: 15px;
    margin-bottom: 8px;
    font-family: 'Georgia', serif;
    cursor: default;
}
.custom-mt {
    margin-top: 30px !important;
}
.info-content {
    /*background-color: #27869b;*/
    /*background: linear-gradient(to right, #d05991, #cb86a4);*/
    background: linear-gradient(to right, #80a46e, #aec7a2);
    padding: 16px;
    color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    cursor: default;
}
.dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #1890ff;
}
.item-content {
    color: white;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}
.label {
    font-weight: bold;
    margin-bottom: 4px;
}
.value {
    color: #f8f9fa;
}
.personal-info-custom {
    min-width: 470px !important;
}

@media (max-width: 767px) { /* Adjust the breakpoint as needed */
    .personal-info-custom {
        min-width: auto !important;
    }
}